.experimental-design-colour-picking {
  order: 1;
  margin-left: auto;

  &__dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid black;
    display: inline-block;
    cursor: pointer;
  }  
}