%level {
    cursor: move;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
};

.level-in-container {
    @extend %level;
    position: absolute;
};

.level-in-independent-variable {
    @extend %level;
    margin-top: 2px;
};

.level__title {
    flex: 0 0 250px;
    order: 0;
    padding-left:  5px;
}

.level__sessions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}