.session-head {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.session-head__header {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.session-head__header__title {
  order: 0;
  flex: 0 0 90px;
}

.session-head__header__delete {
  cursor: pointer;
  order: 1;
  flex: 0 0 30px;
}
