%independent-variable {
    cursor: move;
    background-color: grey;
};

.independent-variable-in-container {
    @extend %independent-variable;
    position: absolute;
};

.independent-variable-in-block {
    @extend %independent-variable;
    margin-top: 2px;
};
