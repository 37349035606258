%xperimental-design {
    border-radius: 4px;
    position: absolute;
    cursor: move;
}

.experimental-design {
    @extend %xperimental-design;

    &__drop-zone {
        background-color: yellow;
        position: relative;
    }
}

.experimental-design-selected {
    @extend %xperimental-design;
    box-shadow: 0 0 50px rgba(81, 203, 238, 1);
    z-index: 1000;
}

.experimental-design__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.experimental-design__header__title-box {
    flex: 0 0 250px;
    order: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__title {
        order: 0;
    }
}

.experimental-design__header__sessions {
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.experimental-design__header__new-session {
    order: 2;
    margin-left: auto;
    flex: 0 0 30px;
}
