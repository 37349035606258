.level-session {
  flex: 0 0 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.level-session__unequal-level {
  flex: 0 0 32px;
}
