.unequal-levels-session-head {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.unequal-levels-session-head__unequal-level {
  flex: 0 0 50px;
}

.unequal-levels-session-head__add {
  flex: 0 0 50px;
}