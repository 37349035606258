%block {
  background-color: white;
  cursor: move;
}

.block-in-container {
  @extend %block;
  position: absolute;
  border: 1px dashed grey;
}

.block-in-experimental-design {
  @extend %block;
  margin-top: 2px;
}

.block__drop-zone {
  background-color: orange;
  position: relative;
}

.block__block-selectors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;  
  &__left-whitespace {
    flex: 0 0 250px;
    order: 0;
  }
}
