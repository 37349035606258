body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.block-in-container, .block-in-experimental-design {
  background-color: white;
  cursor: move; }

.block-in-container {
  position: absolute;
  border: 1px dashed grey; }

.block-in-experimental-design {
  margin-top: 2px; }

.block__drop-zone {
  background-color: orange;
  position: relative; }

.block__block-selectors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .block__block-selectors__left-whitespace {
    flex: 0 0 250px;
    order: 0; }

.container-bin {
  width: 80px;
  height: 80px;
  top: 700px;
  left: 0px;
  position: absolute;
  background-color: red; }

.experimental-design, .experimental-design-selected {
  border-radius: 4px;
  position: absolute;
  cursor: move; }

.experimental-design__drop-zone {
  background-color: yellow;
  position: relative; }

.experimental-design-selected {
  box-shadow: 0 0 50px #51cbee;
  z-index: 1000; }

.experimental-design__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.experimental-design__header__title-box {
  flex: 0 0 250px;
  order: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  .experimental-design__header__title-box__title {
    order: 0; }

.experimental-design__header__sessions {
  order: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.experimental-design__header__new-session {
  order: 2;
  margin-left: auto;
  flex: 0 0 30px; }

.container {
  background-color: lightgrey; }

.independent-variable-in-container, .independent-variable-in-block {
  cursor: move;
  background-color: grey; }

.independent-variable-in-container {
  position: absolute; }

.independent-variable-in-block {
  margin-top: 2px; }

.level-in-container, .level-in-independent-variable {
  cursor: move;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.level-in-container {
  position: absolute; }

.level-in-independent-variable {
  margin-top: 2px; }

.level__title {
  flex: 0 0 250px;
  order: 0;
  padding-left: 5px; }

.level__sessions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.session-head {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.session-head__header {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.session-head__header__title {
  order: 0;
  flex: 0 0 90px; }

.session-head__header__delete {
  cursor: pointer;
  order: 1;
  flex: 0 0 30px; }

.level-session {
  flex: 0 0 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.level-session__unequal-level {
  flex: 0 0 32px; }

.block-session {
  flex: 0 0 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap; }

.unequal-levels-session-head {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.unequal-levels-session-head__unequal-level {
  flex: 0 0 50px; }

.unequal-levels-session-head__add {
  flex: 0 0 50px; }

.experimental-design-colour-picking {
  order: 1;
  margin-left: auto; }
  .experimental-design-colour-picking__dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid black;
    display: inline-block;
    cursor: pointer; }

